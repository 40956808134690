export default [
  {
    text: "Мои данные",
    link: { name: "ProfileData" },
    key: "profile",
    disabled: false,
  },
  {
    text: "Образование",
    link: { name: "Education" },
    key: "education",
    disabled: false,
  },
  // {
  //   text: "Социальные сети",
  //   link: { name: "SocialMedia" },
  //   key: "socials",
  //   disabled: false,
  // },
  {
    text: "Персональные данные",
    link: { name: "PersonalData" },
    key: "personal",
    disabled: false,
  },
];
