<template>
  <div class="profile">
    <Title icon label="Мой профиль" />
    <div class="profile__header">
      <TopSidebar :tabData="filteredProfileBar" />
    </div>
    <router-view />
  </div>
</template>

<script>
import profileBar from "@/enums/profileBar";
import TopSidebar from "@/components/Blocks/TopSidebar";
import Title from "@/components/Blocks/Title";
import { mapState } from "vuex";

export default {
  name: "Profile",
  components: { Title, TopSidebar },
  data() {
    return {
      profileBar,
    };
  },
  computed: {
    filteredProfileBar() {
      let buffer = JSON.parse(JSON.stringify(this.profileBar));

      return buffer.map((item) => {
        if (item.key === "education") {
          item.disabled = !this.disabledFields["isProfileFilled"];
        }
        if (item.key === "personal") {
          item.disabled = !this.disabledFields["isEducationFilled"];
        }
        return item;
      });
    },
    ...mapState("user", ["disabledFields", "user"]),
  },
};
</script>

<style lang="scss">
.profile {
  &__header {
    overflow-x: auto;
  }
}
</style>
